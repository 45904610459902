import request from '../utils/request'
import baseURL from '../utils/config'

//模块管理——新增或者编辑
export function AddOrEdit(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_ModuleManage/AddOrEdit',
        method: 'post',
        data
    })
}
//模块管理——删除
export function Delete(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_ModuleManage/Delete',
      method: 'get',
      params:data
  })
}
//模块管理——详情
export function getDetail(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_ModuleManage/GetDetail',
      method: 'get',
      params:data
  })
}
//模块管理——设置显示隐藏
export function SetEnable(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_ModuleManage/SetEnable',
      method: 'get',
      params:data
  })
}
//模块管理——查询列表数据
export function GetList(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_ModuleManage/GetList',
      method: 'get',
      params:data
  })
}